//js引用设置
require.config({
	baseUrl:'pagecore/plugins/',
	paths:{
		'jquery': ['jquery.min'],
		'toastr': ['toastr/toastr'],
        'bootstrap': ['bootstrap/js/bootstrap.min'],
		'jcarousellite': ['jcarousellite_1.0.1'],
		//'distpicker': ['distpicker/distpicker'],
		'pagination': ['pagination/js/pagination'],
		'summernote': ['summernote/dist/summernote.min'],
		'popper': ['summernote/dist/popper.min'],
		// 'summernote-zh-CN': ['summernote/dist/lang/summernote-zh-CN'],		
		'distpicker.data': ['distpicker/distpicker.data'],
		'artTemplate': ['artTemplate/dist/template'],
		'templateTools':['../js/template'],//模版工具类
		'page':['../js/page'],//分页工具类
		'form':['../js/form'],//表单工具类
		'layer': ['layer/layer.mobile-v2.0/layer_mobile/layer'],
		'jquery.form': ['jquery.form'],
		'config':['../js/config'],
		'jquery.global':['../js/jquery.global'],//全局应用
		'tagsinput':['tagsInput/jquery.tagsinput.min'],//input标签插件
		'alert':['../js/alert'],//消息提醒插件
		'request':['../js/request'],//数据请求插件
		'url':['../js/url'],//通用工具类
		'tool':['../js/tool'],//通用工具类
		'load':['../js/load'],//页面初始加载类
		// 'highlight':['highlight/highlight.pack'],//代码高亮
		

		//===================== web 开始 =================================
		'royalslider':['/skin/js/jquery.royalslider.min'],
		'swiper':['/skin/js/swiper.min'],
		'wow':['/skin/js/wow.min'],
		'cmain':['/skin/js/main'],
		'country':['/skin/js/country'],
		//'cindex':['/skin/js/index'],
		//===================== web 结束 =================================
	},
	shim: {
		'toastr':['jquery'],
		'pagination':['jquery'],
		'bootstrap': ['jquery'],
		'jcarousellite':['jquery'],
		//'distpicker':['jquery','bootstrap','distpicker.data'],
		'jquery.form': ['jquery'],
		'summernote': ['jquery','popper','bootstrap'],
		
		//===================== web 开始 =================================
		'cmain': ['jquery'],
		//'cindex':['jquery'],
		'royalslider':['jquery'],
		'tagsinput':['jquery'],
		//===================== web 结束 =================================
	},
});


//样式表设置
var cssCofig = {
	baseUrl:'pagecore/plugins/',
	paths:{
		//layer 移动端提示框插件样式
		'layer':[
			['layer/layer.mobile-v2.0/layer_mobile/need/layer.css'],
		],
		//toastr 样式
		'toastr':[
			['toastr/toastr.min.css'],
		],
		//pagination 样式
		'pagination_default':[
			['pagination/css/pagination_default.css'],
		],
		//pagination 自定义样式
		'pagination_custom':[
			['pagination/css/pagination_custom.css'],
		],
		//bootstrap 样式
		'bootstrap':[
			['bootstrap/css/bootstrap.css'],
		],
		'summernote':['summernote/dist/summernote.css'],
		// 'highlight':['highlight//styles/default.min'],//代码高亮
		'tagsinput':['tagsInput/jquery.tagsinput.min.css'],
		//测试样式
		'c':[
			['https://npm.taobao.org/css/antd-0.9.1.min.css',true],
			['layer.mobile-v2.0/layer_mobile/need/layer.css'],
			['https://npm.taobao.org/fonts/amadeus-webfont.woff',true],
		],
		'd':[
			['https://npm.taobao.org/css/github-markdown.css',true],
		],
	},
	shim:{
		// 'layer': ['toastr'],
	},
	public:[
		'layer'
	],
};


//获取样式表
function setCss(cssNameObj){

	var cssNameArr = [];
	if( typeof cssNameObj == "string"){
		cssNameArr.push(cssNameObj);
	}
	
	if(Array.isArray(cssNameObj)){
		cssNameArr = cssNameObj;
	}

	if(cssNameArr.length==0){
		return false;
	}
	
	var appendcss = new Array();
	for(var i=0;i<cssNameArr.length;i++){
		var sub_css_name = cssNameArr[i];
		
		//获取依赖的css引用
		var shim_list = cssCofig.shim[sub_css_name];
		shim_list = shim_list?shim_list:[];

		var shim_path = "";
		var shim_name = "";
		for(var j=0;j<shim_list.length;j++){
			shim_name = shim_list[j];
			shim_path = cssCofig.paths[shim_name];
			if(!shim_path) continue;
			shim_path =  ergodic_path(shim_path);
			appendcss = appendcss.concat(shim_path);
		}

		//获取自身的css引用
		var oneself = cssCofig.paths[sub_css_name];
		var oneself_path =  ergodic_path(oneself);
		appendcss = appendcss.concat(oneself_path);

	}

	var arrlen =  appendcss.length;

	if(arrlen == 0) return;

	if(arrlen > 1 ){
		appendcss = uniq(appendcss);
	}

	for( var i = 0; i < appendcss.length; i++ ){
		addSheetFile(appendcss[i]);
	}	

}

//全局css设置
if(cssCofig.public.length > 0){
	for(var j=0;j<cssCofig.public.length;j++){
		setCss(cssCofig.public[j]);
	}
}

//追加样式表
function addSheetFile(path){ 
	var fileref = document.createElement("link") 
	fileref.rel = "stylesheet"; 
	fileref.type = "text/css"; 
	fileref.href = path; 
	fileref.media = "screen"; 
	// var headobj = document.getElementsByTagName('head')[0]; 
	// headobj.appendChild(fileref); 

	var headobj = document.getElementsByClassName("stylemain")[0];//document.getElementById('cssmain'); 
	//*当错误指向此处时,则表明文件不存在,但不影响后续脚本的运行
	headobj.before(fileref); 
} 

//遍历css路劲
function ergodic_path(patharr){
	var mainarr = new Array();
	var this_path = "";
	var is_allpath = 0;
	if(patharr==undefined) return [];
	for(var i=0;i<patharr.length;i++){
		this_path = patharr[i][0];
		is_allpath = patharr[i][1];
		if(!is_allpath){
			this_path = cssCofig.baseUrl + this_path;
		}
		mainarr.push(this_path);
	}

	return mainarr;
}

//数组去重复
function uniq(arr) {
	if( !Array.isArray( arr ) || !arr.length ) {
	  return arr;
	}
	let map = new Map();
	arr.forEach( value => map.set(value, value) );
	return Array.from( map.values() );
}

//模版对象
var template = {};